import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './MainStyle.css';
import { Link } from 'react-router-dom';

const OurCauses = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    const causesData = [
        {
            id: '1',
            image: 'images/38.jpg',
            title: 'Covid-19 Health Care Awareness',
            description: 'PWC is a non-governmental charitable organization aiming to provide easy access to promotional.'
        },
        {
            id: '2',
            image: 'images/help.jpg',
            title: 'Girl Child Education Empower',
            description: 'PWC is non-governmental charitable organisation wish to ensure empowerment in the true sense.'
        },
        {
            id: '3',
            image: 'images/ngo.jpg',
            title: 'Help Capable Underprivilege',
            description: 'PWC is non-governmental charitable organisation that helps train unprivileged youth to become workers.'
        },
        {
            id: '4',
            image: 'images/people.jpg',
            title: 'Migrant Families Empowerment',
            description: 'PWC is non-governmental charitable organisation enabling diverse positive interaction to build up belongingness.'
        }
    ];

    return (
        <section className="causes-section bd-bottom padding">
            <div className="container">
                <div className="section-heading text-center mb-40">
                    <div className="sec-heading">
                        <h2><strong style={{ color: "#16846e" }}>Our</strong> <strong style={{ color: "#f5ab21" }}>Causes</strong></h2>
                        <span className="heading-border" />
                    </div>
                </div>
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={6000}
                    keyBoardControl={true}
                    customTransition="transform 300ms ease-in-out"
                    transitionDuration={300}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {causesData?.map((cause, index) => (
                        <div key={index} className="causes-card" style={{ margin: '0 10px' }}>
                            <div className="causes-content">
                                <div className="causes-thumb">
                                    <img src={cause.image} alt="causes" />
                                </div>
                                <div className="causes-details">
                                    <h3>{cause.title}</h3>
                                    <p>{cause.description}</p>
                                    <Link to={`ourcause?causeid=${cause?.id}`}>Read More</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </section>
    );
};

export default OurCauses;
