import React from 'react'
import './MainStyle.css'

const Testimonials = () => {
    return (
        <>
            <section className="testimonials" style={{ background: `url("images/parallax.jpg")` }}>
                <div className="heading text-center">
                    <h2>
                        Our People <span> Says </span>
                    </h2>
                </div>
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div
                                id="carouselExampleIndicators"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <ol className="carousel-indicators">
                                    <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to={0}
                                        className="active"
                                    />
                                    <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                                    <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active text-center">
                                        <img
                                            src="images/kiren.jpg"
                                            alt=""
                                            className="center-block team"
                                        />
                                        <h3>MRS. KIRAN TIRKEY</h3>
                                        <h4>Volunteer</h4>
                                        <p>
                                            Hallo Friends. I wish to share my experience of working together during Covid-19 Pandemic National Lockdown-2020. On the first day when Pallottine Welfare Centre served cooked food to the migrant workers, I was struck by the pathetic situations of migrants.
                                            They didn’t even have food for many days. I asked a question to Fr. Rashmikant Nayak the director for how long to serve the food for them.
                                        </p>
                                    </div>
                                    {/* Item-2 */}
                                    <div className="carousel-item text-center">
                                        <img
                                            src="images/arpana.jpg"
                                            alt=""
                                            className="center-block team"
                                        />
                                        <h3>MISS. ARPANA KERKETTA</h3>
                                        <h4>Volunteer</h4>
                                        <p>
                                            Hallo friends. It was the plan of God that we could serve the Covid-19 affected humanity with a sense of feeling under the guidance of Fr.Rashmikant nayak, PWC Director. We served the cooked food to the hungry migrants who had not eaten food for many days. I experienced that they were very happy to receive our food. I learned a lot from this situation such as committed service attitude, giving quality time .
                                        </p>
                                    </div>
                                    {/* Item-3 */}
                                    <div className="carousel-item text-center">
                                        <img
                                            src="images/nitin.jpg"
                                            alt=""
                                            className="center-block team"
                                        />
                                        <h3>MR. NITIN MINJ</h3>
                                        <h4>Volunteer</h4>
                                        <p>
                                            Hey Friends. I expressed my gratitude to God for joining in such a great cause of feeding the migrants. We worked for the people so that they could be satisfied. We involved ourselves for the humanitarian cause to feed the hungry. For me, the hungry migrants were my neighbor because we served cooked food for them and gave water to drink. I feel proud of being part of this group and welfare center for doing great charitable work during the lockdown for 90 days.
                                        </p>
                                    </div>
                                </div>
                                <button
                                    className="carousel-control-prev control"
                                    data-target="#carouselExampleIndicators"
                                    data-slide="prev"
                                >
                                    <span className="fa fa-angle-left icon" aria-hidden="true" />
                                    <span className="sr-only">Previous</span>
                                </button>
                                <button
                                    className="carousel-control-next control"
                                    data-target="#carouselExampleIndicators"
                                    data-slide="next"
                                >
                                    <span className="fa fa-angle-right icon" aria-hidden="true" />
                                    <span className="sr-only">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Testimonials
