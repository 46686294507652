import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

const RecentNews = () => {
    const search = useLocation().search;
    const newsId = new URLSearchParams(search).get("newsid");

    const recentnews = useMemo(() => [
        {
            id: '1',
            image: 'images/basic.jpg',
            albumgallery: [
                'images/39.jpg',
                'images/40.jpg',
                'images/42.jpg'
            ],
            date: 'September 21 - 2017',
            tags: 'Donations,Fundraising,Sponsership',
            title: 'Basic Coaching Class For Poor Children Class up to 5th',
            content: 'Khrist Jyoti Province,Raipur Basic Coaching Class for poor Children class up to 5th By Pallottine Welfare Centre (PWC) Shanti Niwas,Bilaspur.'
        },
        {
            id: '2',
            image: 'images/Education.jpg',
            albumgallery: [
                'images/Education.jpg',
                'images/39.jpg',
                'images/42.jpg'
            ],
            date: 'September 20 - 2017',
            tags: 'Donations,Fundraising,Sponsership',
            title: 'Education Kit for Children of Poor & Migrant Families',
            content: 'Education Kit for Children of Poor & Migrant Families Arranged by Pallotine Welfare Centre Shanti Niwas, Bilaspur Fr. Rashmikant Nayak.'
        },
        {
            id: '3',
            image: 'images/migrant.jpg',
            albumgallery: [
                'images/32.jpg',
                'images/38.jpg',
                'images/35.jpg'
            ],
            date: 'September 19 - 2017',
            tags: 'Donations,Fundraising,Sponsership',
            title: 'Migrant Workers',
            content: 'PWC is a non-governmental charitable organization that helps restore the Socio- economic abilities of children, youth, and women.It promotes social justice to build a society that promises equality, justice, and dignity to all irrespective of their birth.PEC has been focussing on alleviating poverty and social exclusion.We undertake comprehensive programs in health, education, livelihoods, and disaster preparedness and response.Collaborators and people of goodwill, are instrumentals in fighting the Covid - 19 health crisis by distributing dry rations to feed the needy & poor.'
        }
    ], []);

    useEffect(() => {
    }, [recentnews]);

    const [newsData, setNewsData] = useState(null);

    useEffect(() => {
        if (newsId) {
            const newsItem = recentnews.find(item => item.id === newsId);
            setNewsData(newsItem || null);
        }
    }, [newsId, recentnews]);

    if (!newsData) {
        return <div>No News Found</div>;
    }

    return (
        <>
            <section className="blog-section bg-grey padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 sm-padding">
                            <div className="blog-items single-post row">
                                <img src={newsData.image} alt="blog post" />
                                <h2 className="mt-3">{newsData.title}</h2>
                                <p className="mt-3">
                                    {newsData.content}
                                </p>
                                <div className="row mt-3">
                                    {newsData?.albumgallery?.map((image, index) => (
                                        <div className="col-md-3" key={index}>
                                            <img src={image} alt={`${index}`} className="img-fluid mb-3" />
                                        </div>
                                    ))}
                                </div>
                                <div className="share-wrap mt-4">
                                    <h4>Share This Article</h4>
                                    <ul className="share-icon">
                                        <li>
                                            <a href="https://www.facebook.com/pallottinewelfarecentre.pwc" target='_blank' rel='noreferrer'>
                                                <i className="ti-facebook" /> Facebook
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/webinane" target='_blank' rel='noreferrer'>
                                                <i className="ti-twitter" /> Twitter
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCstkLGOKUY3Kv8Ij89SVNSg" target='_blank' rel='noreferrer'>
                                                <i className="ti-youtube" /> YouTube
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://themes.webinane.com/wp/lifeline/" target='_blank' rel='noreferrer'>
                                                <i className="ti-instagram" /> Instagram
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 sm-padding">
                            <div className="sidebar-wrap">
                                <div className="sidebar-widget mb-50">
                                    <h4>Recent News</h4>
                                    <ul className="recent-posts">
                                        {recentnews.map((recent, index) => (
                                            <li key={index}>
                                                <img src={recent.image} alt="blog post" />
                                                <div>
                                                    <h4>
                                                        <Link to={`/recentnews?newsid=${recent?.id}`}>{recent.title}</Link>
                                                    </h4>
                                                    <span className="date">
                                                        <i className="fa fa-clock-o" /> {recent.date}
                                                    </span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="sidebar-widget mb-50">
                                    <h4>Tags</h4>
                                    <ul className="tags">
                                        {newsData.tags.split(',').map((tag, index) => (
                                            <li key={index}>
                                                <a href="/">{tag.trim()}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RecentNews;
