import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

const RecentCause = () => {
    const search = useLocation().search;
    const causeid = new URLSearchParams(search).get("causeid");

    const causesData = useMemo(() => [
        {
            id: '1',
            image: 'images/38.jpg',
            title: 'Covid-19 Health Care Awareness',
            description: 'PWC is a non-governmental charitable organization aiming to provide easy access to promotional.'
        },
        {
            id: '2',
            image: 'images/help.jpg',
            title: 'Girl Child Education Empower',
            description: 'PWC is non-governmental charitable organisation wish to ensure empowerment in the true sense.'
        },
        {
            id: '3',
            image: 'images/ngo.jpg',
            title: 'Help Capable Underprivilege',
            description: 'PWC is non-governmental charitable organisation that helps train unprivileged youth to become workers.'
        },
        {
            id: '4',
            image: 'images/people.jpg',
            title: 'Migrant Families Empowerment',
            description: 'PWC is non-governmental charitable organisation enabling diverse positive interaction to build up belongingness.'
        }
    ], []);
    const [newsData, setNewsData] = useState(null);

    useEffect(() => {
        if (causeid) {
            const newsItem = causesData.find(item => item.id === causeid);
            setNewsData(newsItem || null);
        }
    }, [causeid, causesData]);

    if (!newsData) {
        return <div>No News Found</div>;
    }


    return (
        <>
            <section className="blog-section bg-grey padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 sm-padding">
                            <div className="blog-items single-post row">
                                <img src={newsData.image} alt="blog post" style={{ height: "40rem" }} />
                                <h2 className="mt-3">{newsData.title}</h2>
                                <p className="mt-3">
                                    {newsData.description}
                                </p>
                                <div className="share-wrap">
                                    <h4>Share This Article</h4>
                                    <ul className="share-icon">
                                        <li>
                                            <a href="https://www.facebook.com/pallottinewelfarecentre.pwc" target='_blank' rel='noreferrer'>
                                                <i className="ti-facebook" /> Facebook
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/webinane" target='_blank' rel='noreferrer'>
                                                <i className="ti-twitter" /> Twitter
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCstkLGOKUY3Kv8Ij89SVNSg" target='_blank' rel='noreferrer'>
                                                <i className="ti-youtube" /> YouTube
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://themes.webinane.com/wp/lifeline/" target='_blank' rel='noreferrer'>
                                                <i className="ti-instagram" /> Instagram
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 sm-padding">
                            <div className="sidebar-wrap">
                                <div className="sidebar-widget mb-50">
                                    <h4>Recent News</h4>
                                    <ul className="recent-posts">
                                        {causesData.map((cause, index) => (
                                            <li key={index}>
                                                <img src={cause.image} alt="blog post" />
                                                <div>
                                                    <h4>
                                                        <Link to={`/ourcause?causeid=${cause?.id}`}>{cause.title}</Link>
                                                    </h4>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RecentCause
