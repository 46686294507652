import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  async function onSubmitForm(data, e) {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: { name: "pwckjp", email: data.email },
          to: [{ email: "muniraj@dbcyelagiri.edu.in" }],
          subject: "Contact Request for Pallottine welfare centre",
          htmlContent: `<!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Contact Form Email</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
            <style>
              body {
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                background-color: #f8f9fa;
                padding: 20px;
              }
              .email-container {
                max-width: 600px;
                margin: 0 auto;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                padding: 30px;
              }
              .message-box {
                padding: 20px;
                border-left: 4px solid #007bff;
                font-style: italic;
                background-color: #f0f0f0;
                margin-top: 20px;
                border-radius: 5px;
              }
              .btn {
                background-color: #007bff;
                color: #fff;
                padding: 10px 20px;
                border-radius: 5px;
                text-decoration: none;
                transition: background-color 0.3s ease;
              }
              .btn:hover {
                background-color: #0056b3;
                color:#fff
              }
              h2 {
                color: #007bff;
                text-align: center;
                margin-bottom: 20px;
              }
              p {
                font-size: 16px;
                line-height: 1.6;
              }
              b {
                color: #007bff;
              }
              .footer-text {
                text-align: center;
                margin-top: 20px;
                font-size: 14px;
              }
              .footer-text a {
                color: #007bff;
                text-decoration: none;
              }
              .footer-text a:hover {
                text-decoration: underline;
              }
            </style>
          </head>
          <body>
            <div class="email-container">
              <h2>New Message from ${data.name}</h2>
              <p>Hello <b>Admin,</b></p>
              <div class="message-box">
                    <p><strong>Name:</strong> ${data.name}</p>
                    <p><strong>Email:</strong> ${data.email}</p>
                    <p><strong>Message:</strong> ${data.message}</p>
              </div>
              <p>If you want to visit our Website, click the button below:</p>
              <a href="https://pwckjp.org/" target="_blank" class="btn">Visit Website</a>
            </div>
          </body>
          </html>`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key":
              "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",
          },
        }
      );
      if (response.status === 201) {
        Swal.fire({
          title: "Success!",
          text: "Your request has been sent successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        throw new Error("Failed to send email.");
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error!",
        text: "Failed to send message. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      // Reset the form
      e.target.reset();
    }
  }

  return (
    <>
      <div className="header-height" />

      <section className="contact-section padding">
        {/* <div id="google_map"></div> */}
        <div className="container">
          <h3>Get in touch</h3>
          <p>
            Pallottine welfare centre began as non-profitable charitable
            organization to help out poorest of the poor with right motive of
            helping the needy. The social actions being carried in different
            forms in different places.
          </p>{" "}
          <p>
            Khrist Jyoti Provuice Raipur, has extended its work by way of taking
            care of Lepers, Db Tech training centre for youth, Shelter home for
            unprivileged children, Covid-19 response to livelihood, helping and
            educating the bright students from remote areas. Such noble
            initiatives are taken as priority of the Welfare Centre. New
            upcoming projects are being planned and will be implemented in the
            present financial year.
          </p>
          <div className="row contact-wrap">
            <div className="col-md-6 xs-padding">
              <div className="contact-info">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14788.852765715563!2d82.13216042146318!3d22.07967375113602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a280b117b9ab6a7%3A0xc6f3f89d9eac7caf!2sBilaspur%2C%20Chhattisgarh%20495001!5e0!3m2!1sen!2sin!4v1594373464297!5m2!1sen!2sin"
                  width="100%"
                  title="pwckjp"
                  height="200"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"></iframe>

                <ul>
                  <br />
                  <li>
                    <i className="ti-location-pin" /> Pallottine Welfare Centre
                    (PWC), Shanti Niwas, Bilaspur, Aawas Para, Chatauna, Behind
                    New High Court , P. O. Hirai Mines, Dist. Bilaspur ( C.G.) -
                    495220
                  </li>
                  <li>
                    <i className="ti-mobile" />
                    <a href="tel:7587254532" className="emil">
                      +91 7587254532
                    </a>
                  </li>
                  <li>
                    <i className="ti-email" />
                    <a
                      href="mailto:pwcbilaspur@rediffmail.com"
                      className="emil">
                      pwcbilaspur@rediffmail.com
                    </a>
                  </li>
                  <li>
                    <i className="ti-link" />
                    <a href="http://pwckjp.org">http://pwckjp.org</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 xs-padding">
              <div className="contact-form">
                <h3>Contact Us By Message</h3>
                <p>
                  Your email address will not be published. Required fields are
                  marked *
                </p>
                <form
                  onSubmit={handleSubmit(onSubmitForm)}
                  className="form-horizontal">
                  <div className="form-group colum-row row">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        {...register("name", { required: true })}
                        aria-invalid={errors?.name ? "true" : "false"}
                        className="form-control"
                        placeholder="Name"
                      />
                      {errors?.name?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Name is required</label>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        {...register("email", { required: true })}
                        aria-invalid={errors?.email ? "true" : "false"}
                        className="form-control"
                        placeholder="Email"
                        required=""
                      />
                      {errors?.email?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Email is required</label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <textarea
                        id="message"
                        name="message"
                        cols={30}
                        rows={5}
                        {...register("message", { required: true })}
                        aria-invalid={errors?.message ? "true" : "false"}
                        className="form-control message"
                        placeholder="Message"
                      />
                      {errors?.message?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">
                            Message is required
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <button id="submit" className="default-btn" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                  <div id="form-messages" className="alert" role="alert" />
                </form>
              </div>
              <div className="social-connect">
                <br />
                <div className="container">
                  <h3>Find Us on Social Media</h3>
                  <ul className="social-bar" style={{ display: "flex" }}>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/pallottinewelfarecentre.pwc">
                        <img alt="Not Found" src="images/fb.png" />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noreferrer" href="https://twitter.com/webinane">
                        <img alt="Not Found" src="images/twitter-icon.jpg" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://themes.webinane.com/wp/lifeline/">
                        <img alt="Not Found" src="images/insta.jpg" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.youtube.com/channel/UCstkLGOKUY3Kv8Ij89SVNSg">
                        <img alt="Not Found" src="images/youtube.jpg" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
