import React from "react";

const OurVission = () => {
  return (
    <>
      <section className="block">
        <div className="client-reviews">
          <ul className="slides">
            <li
              className="flex-active-slide"
              style={{
                width: "100%",
                marginRight: "-100%",
                position: "relative",
                opacity: 1,
                display: "block",
                zIndex: 2,
              }}>
              <div className="container">
                <div className="card mt-5 mb-4 shadow ">
                  <div className="card-body">
                    <h3 className="text-left">
                      <span style={{ color: "red" }}>Vision</span>{" "}
                      <span> : </span>
                      Socio-economic Empowerment and Social Justice
                    </h3>
                    <p>
                      Socio-economic empowerment and social justice strive to
                      uplift communities by addressing inequalities, fostering
                      inclusivity, and promoting fair access to resources and
                      opportunities, ultimately creating a more equitable and
                      just society.
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default OurVission;
