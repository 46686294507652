import React from "react";

const About = () => {
  return (
    <>
      <section className="about-section bd-bottom shape circle padding">
        <div className="container">
          <h2>About Us</h2>
          <p>
            Pallottine welfare centre began as non-profitable charitable
            organization to help out poorest of the poor with right motive of
            helping the needy. The social actions being carried in different
            forms in different places. Khrist Jyoti Provuice Raipur, has
            extended its work by way of taking care of Lepers, Db Tech training
            centre for youth, Shelter home for unprivileged children, Covid-19
            response to livelihood, helping and educating the bright students
            from remote areas. Such noble initiatives are taken as priority of
            the Welfare Centre. New upcoming projects are being planned and will
            be implemented in the present financial year.
          </p>
          <div className="row">
            <div className="gallery">
              <div className="gallery-item">
                <img src="images/slidernew2.jpg" alt="Social-Economic" />
              </div>
              <div className="gallery-item">
                <img src="images/slidernew1.jpg" alt="Pallottine" />
              </div>
              <div className="gallery-item">
                <img src="images/22.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/29.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/36.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/37.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/8.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/35.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/38.jpg" alt="IMG_20200524_114858" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
