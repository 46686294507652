import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

const Detailedongoing = () => {
  const search = useLocation().search;
  const projectid = new URLSearchParams(search).get("projectid");

  const causesData = useMemo(() => [
    {
      id: "1",
      image: "images/39.jpg",
      title: "Education Kits Distribution",
      description:
        "PWC intends to open few basic Coaching Centres for poor children to aquire primary education. The direct beneficiaries are from poor and migrant families who have away during Covid-19 Pandemic. The centre will take care their necessary education items so that the parents will have less financial burden.",
      images: [
        "images/39.jpg",
        "images/40.jpg",
        "images/41.png",
        "images/42.jpg",
      ],
    },
    {
      id: "2",
      image: "images/35.jpg",
      title: "Employment Generating Projects",
      description:
        "PWC has taken steps to empower the village business attitudes of the people by creating Cooperative Shops of income. By which they will be economically strong. The motto of creating such avenues to help each other in real need.",
      images: ["images/30.jpg", "images/43.jpg", "images/migrant.jpg"],
    },

    {
      id: "3",
      image: "images/19.jpg",
      title: "Families/Migrant Workers",
      description:
        "Covid-19 has brought massive trauma to the people in and around. Migrant workers and poor people are the most victims. PWC has planned to executive relief items to the families as well as to provide books and stationeries to the needy children.",
      images: [
        "images/19.jpg",
        "images/20.jpg",
        "images/21.jpg",
        "images/22.jpg",
        "images/23.jpg",
        "images/24.jpg",
        "images/25.jpg",
      ],
    },
    {
      id: "4",
      image: "images/42.jpg",
      title: "Students",
      description:
        "PWC intends to open few basic Coaching Centres for poor children to aquire primary education. The direct beneficiaries are from poor and migrant families who have away during Covid-19 Pandemic. The centre will take care their necessary education items so that the parents will have less financial burden.",
      images: [
        "images/39.jpg",
        "images/40.jpg",
        "images/41.png",
        "images/42.jpg",
      ],
    },
    {
      id: "5",
      image: "images/30.jpg",
      title: "Livelihood & Sustainable Life",
      description:
        "The project aims to contribute PWC efforts to eliminate poverty through various livelihood projects such as Community Kitchen, Kitchen garden, piggery, Breeding goats, orchard, seasonal business etc whereby many families will have good earnings for their life.",
      images: [
        "images/32.jpg",
        "images/34.jpg",
        "images/35.jpg",
        "images/44.jpg",
      ],
    },

    {
      id: "6",
      image: "images/34.jpg",
      title: "Poor/ Destitutes/Beggars",
      description:
        "PWC has initiated an action more basic to the vulnerable group of people at Bilaspur. The centre by the help of Khrist Jyoti Province and St. Vincent Pallotti church, 27 Kholi , Bilaspur wish to feed 300 poor, destitute, beggars, rich -show pullers etc on every Sunday. The name of the project is called “PALLOTTI AHAAR”.",
      images: [
        "images/19.jpg",
        "images/20.jpg",
        "images/21.jpg",
        "images/22.jpg",
        "images/23.jpg",
        "images/24.jpg",
        "images/25.jpg",
      ],
    },
  ], []);
  const [newsData, setNewsData] = useState(null);

  useEffect(() => {
    if (projectid) {
      const newsItem = causesData.find((item) => item.id === projectid);
      setNewsData(newsItem || null);
    }
  }, [projectid, causesData]);

  if (!newsData) {
    return <div>No News Found</div>;
  }

  return (
    <>
      <section className="blog-section bg-grey padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 sm-padding">
              <div className="blog-items single-post row">
                <img src={newsData.image} alt="blog post" />
                <h2 className="mt-3">{newsData.title}</h2>
                <p className="mt-3">{newsData.description}</p>
                <div className="row">
                  {newsData.images.map((image, index) => (
                    <div className="col-md-3 mt-2" key={index}>
                      <img src={image} alt="blog post" />
                    </div>
                  ))}
                </div>

                <div className="share-wrap">
                  <br />
                  <h4>Share This Article</h4>
                  <ul className="share-icon">
                    <li>
                      <a href="/">
                        <i className="ti-facebook" /> Facebook
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="ti-twitter" /> Twitter
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="ti-google" /> Google+
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="ti-instagram" /> Instagram
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="ti-linkedin" /> Linkedin
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 sm-padding">
              <div className="sidebar-wrap">
                <div className="sidebar-widget mb-50">
                  <h4>Recent News</h4>
                  <ul className="recent-posts">
                    {causesData.map((cause, index) => (
                      <li key={index}>
                        <img src={cause.image} alt="blog post" />
                        <div>
                          <h4>
                            <Link
                              to={`/our-on-going-project?projectid=${cause?.id}`}>
                              {cause.title}
                            </Link>
                          </h4>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Detailedongoing;
