import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [categories] = useState([
    {
      id: 1,
      name: "Events",
      subcategories: [
        {
          id: 11,
          title: "Dry Ration Distributions",
          content: "Dry Ration Distributions",
          images: [
            "images/48.jpg",

            "images/20.jpg",
            "images/21.jpg",
            "images/22.jpg",
            "images/23.jpg",
            "images/19.jpg",
          ],
        },
        {
          id: 12,
          title: "Migrants Workers",
          content: "Migrants Workers",
          images: [
            "images/21.jpg",
            "images/22.jpg",
            "images/23.jpg",
            "images/24.jpg",
            "images/25.jpg",
            "images/48.jpg",
            "images/49.jpg",
          ],
        },
        {
          id: 13,
          title: "Child Education",
          content: "Girl Child Education Empowerment",
          images: [
            "images/39.jpg",
            "images/40.jpg",
            "images/41.png",
            "images/42.jpg",
          ],
        },
        {
          id: 14,
          title: "Covid-19",
          content: "Covid-19 Health Care Awareness to Aged",
          images: [
            "images/35.jpg",
            "images/43.jpg",
            "images/44.jpg",
            "images/covid19.jpg",
            "images/32.jpg",
          ],
        },
        {
          id: 15,
          title: "Help Capable Underprivileged Youth",
          content: "Help Capable Underprivileged Youth",
          images: ["images/33.jpg", "images/48.jpg", "images/43.jpg"],
        },
        {
          id: 16,
          title: "Families Empowerment",
          content: "Migrant Families Empowerment",
          images: ["images/49.jpg", "images/50.jpg", "images/48.jpg"],
        },
      ],
    },
  ]);

  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    categories[0]?.subcategories[0] || null
  );

  // const handleCategoryClick = (category) => {
  //   setSelectedCategory(category);
  //   setSelectedSubcategory(category.subcategories[0] || null);
  // };

  // const handleSubcategoryClick = (subcategory) => {
  //   setSelectedSubcategory(subcategory);
  // };

  // useEffect to set the initial state when the component mounts
  useEffect(() => {
    setSelectedCategory(categories[0]);
    setSelectedSubcategory(categories[0]?.subcategories[0] || null);
  }, [categories]);

  // Function to open the popup/modal with the selected image
  const openImagePopup = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Function to close the popup/modal
  const closePopup = () => {
    setSelectedImage(null);
  };
  return (
    <div className="container">
      <br />
      {/* <div className="mt-4">
        <ul className="gallerylist">
          {categories.map((category) => (
            <li
              key={category.id}
              onClick={() => handleCategoryClick(category)}
              style={{
                cursor: "pointer",
                fontWeight:
                  selectedCategory.id === category.id ? "bold" : "normal",
              }}>
              {category.name}
            </li>
          ))}
        </ul>
      </div> */}

      <Tabs
        id="category-tabs"
        className="shadow"
        activeKey={selectedSubcategory ? selectedSubcategory.id : ""}
        onSelect={(key) => {
          const subcategory = selectedCategory.subcategories.find(
            (sub) => sub.id === parseInt(key)
          );
          setSelectedSubcategory(subcategory);
        }}>
        {selectedCategory &&
          selectedCategory.subcategories.map((subcategory) => (
            <Tab
              key={subcategory.id}
              eventKey={subcategory.id.toString()}
              title={subcategory.title}>
              {/* <div className="card mt-5 mb-4 shadow"> */}
              <div className="container mt-4">
                <div className="row">
                  <h2 className="text-center galletytitle">
                    <strong style={{ color: "rgb(245, 171, 33)" }}>
                      {subcategory.content}
                    </strong>
                  </h2>
                  {subcategory.images.map((image, index) => (
                    <div
                      key={index}
                      className="col-md-4 col-sm-6 "
                      onClick={() => openImagePopup(image)}
                      style={{ marginBottom: "10px", cursor: "pointer" }}>
                      <img
                        src={image}
                        alt={`${subcategory.title}  ${index + 1}`}
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </div>
                  ))}
                </div>

                {selectedImage && (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.75)",
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                      justifyContent: "center", // Vertically center in the available space
                      alignItems: "center", // Horizontally center
                      zIndex: 2,
                      cursor: "pointer",
                    }}
                    onClick={closePopup}>
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{
                        width: "70%", // Limits image width to 80% of viewport width
                        height: "70%", // Limits image height to 80% of viewport height
                        // objectFit: "contain",
                        marginTop: "10%", // Adds some space at the top to push the image down
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />

                    <button
                      onClick={closePopup}
                      style={{
                        position: "fixed",
                        right: "40px",
                        cursor: "pointer",
                        padding: "10px 15px",
                        fontSize: "16px",
                        borderRadius: "50%",
                        border: "none",
                        backgroundColor: "#fff",
                        color: "black",
                      }}>
                      X
                    </button>
                  </div>
                )}
              </div>
              {/* </div> */}
            </Tab>
          ))}
      </Tabs>
    </div>
  );
};

export default Gallery;
