import React from "react";
import { Link } from "react-router-dom";

const Ongoing = () => {
  return (
    <>
      <section className="team-section bg-grey bd-bottom circle shape padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 xs-padding">
              <div className="blog-items grid-list row">
                <div className="col-md-4 padding-15">
                  <div className="blog-post">
                    <img src="images/39.jpg" alt="blog post" />
                    <div className="blog-content">
                      <h3>Education Kits Distribution</h3>
                      <p style={{ color: "#f56f00" }}>
                        Needed Donation <span>$ 10000</span>
                      </p>
                      <p>
                        PWC intends to open few basic Coaching Centres for poor
                        children to aquire primary education. The direct
                        beneficiaries are from poor and migrant families who
                      </p>
                      <Link
                        className="linkstyle"
                        to="/our-on-going-project?projectid=1">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 padding-15">
                  <div className="blog-post">
                    <img src="images/35.jpg" alt="blog post" />
                    <div className="blog-content">
                      <h3>Employment Generating Projects</h3>
                      <p style={{ color: "#f56f00" }}>
                        Needed Donation <span>$ 10000</span>
                      </p>
                      <p>
                        PWC has taken steps to empower the village business
                        attitudes of the people by creating Cooperative Shops of
                        income. By which they will be economically strong
                      </p>
                      <Link
                        className="linkstyle"
                        to="/our-on-going-project?projectid=2">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 padding-15">
                  <div className="blog-post">
                    <img src="images/migrant.jpg" alt="blog post" />
                    <div className="blog-content">
                      <h3>Families/Migrant Workers</h3>
                      <p style={{ color: "#f56f00" }}>
                        Needed Donation <span>$ 12000</span>
                      </p>
                      <p>
                        Covid-19 has brought massive trauma to the people in and
                        around. Migrant workers and poor people are the most
                        victims. PWC has planned to executive relief ite
                      </p>
                      <Link
                        className="linkstyle"
                        to="/our-on-going-project?projectid=3">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 padding-15">
                  <div className="blog-post">
                    <img src="images/30.jpg" alt="blog post" />
                    <div className="blog-content">
                      <h3>Students</h3>
                      <p style={{ color: "#f56f00" }}>
                        Needed Donation <span>$ 15000</span>
                      </p>
                      <p>
                        The project aims to contribute PWC efforts to eliminate
                        poverty through various livelihood projects such as
                        Community Kitchen, Kitchen garden,
                      </p>
                      <Link
                        className="linkstyle"
                        to="/our-on-going-project?projectid=4">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 padding-15">
                  <div className="blog-post">
                    <img src="images/34.jpg" alt="blog post" />
                    <div className="blog-content">
                      <h3>Livelihood & Sustainable Li</h3>
                      <p style={{ color: "#f56f00" }}>
                        Needed Donation <span>$ 12000</span>
                      </p>
                      <p>
                        PWC has initiated an action more basic to the vulnerable
                        group of people at Bilaspur. The centre by the help of
                        Khrist Jyoti Province and St. Vincent Pallotti
                      </p>
                      <Link
                        className="linkstyle"
                        to="/our-on-going-project?projectid=5">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 padding-15">
                  <div className="blog-post">
                    <img src="images/39.jpg" alt="blog post" />
                    <div className="blog-content">
                      <h3>Poor/ Destitutes/Beggars</h3>
                      <p style={{ color: "#f56f00" }}>
                        Needed Donation <span>$ 12000</span>
                      </p>
                      <p>
                        PWC intends to open few basic Coaching Centres for poor
                        children to aquire primary education. The direct
                        beneficiaries are from poor
                      </p>
                      <Link
                        className="linkstyle"
                        to="/our-on-going-project?projectid=6">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ongoing;
