import React from "react";

const History = () => {
  return (
    <>
      <div className="container">
        <div className="card mt-5 mb-4 shadow ">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <img
                  src="images/slidernew2.jpg"
                  className="img-fluid rounded-start"
                  alt="..."
                />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <p className="card-text">
                    Pallottine Welfare Centre is a non-profit charitable
                    organisation which challenges the social and economic
                    inequalities in our society and seeks to empower the
                    vulnerable categories of people in order to make them
                    independent to help themselves.&nbsp; Pallottine Welfare
                    Centre began with the aim to correct, minimise and remedy
                    the socio-economic conditions of injustice in our society.
                  </p>
                  <p className="card-text">
                    The Vision and Mission of the Centre is to fashion, with the
                    involvement of people themselves, just and fair
                    socio-economic conditions of life in our society that every
                  </p>
                </div>
              </div>
              <p>
                individual person and every group can have good standard of
                living worthy of his/her human dignity as well as stand with
                everyone else in equal dignity and human status before the law
                and in the society.&nbsp; For this purpose the Welfare Centre
                gives special attention to the disadvantaged and vulnerable
                sections of the society seeking to empower them so that they can
                stand up on their own as equal citizens of our country and ask
                from powers that be for what is rightfully theirs.
              </p>
            </div>
          </div>
        </div>
        <div className="card mt-5 mb-4 shadow ">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <img
                  src="images/18.png"
                  className="img-fluid rounded-start"
                  alt="..."
                />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <p className="card-text">
                    The Pallottine Welfare Centre was established in the year
                    2012 by the initiative of Very Rev. Fr. Shanti Prakash
                    Panna, the former Provincial Rector of Khrist Jyoti
                    Province, Raipur.&nbsp; The Provincial Rector is also the{" "}
                    <b>ex officio</b> president of the Registered Society.&nbsp;
                    Rev. Fr. Rashmikant Nayak was the first Director of the
                    Pallottine Welfare Centre as well as its Secretary.
                  </p>
                  <p className="card-text">
                    In keeping with its vision and mission the Pallottine
                    Welfare Centre intends to render charitable services to the
                    needy and the disadvantaged involving itself in
                    socio-economic activities, like, supporting children homes,
                    running Db Tech and Coaching Centres, adopting children for
                    education, extending educational sponsorship programmes,
                    etc. The Centre has reached out to three states, viz.,
                    Chattisgath, Odisha and Jharkhand, by way of social and
                    charitable works.
                  </p>
                  <p>
                    Pallottine Welfare Centre seeks also to engage vulnerable
                    “at risk” children through in-school leadership programmes
                    and after-school academics (skill building) that would help
                    these children build up their confidence and self-esteem as
                    well as with income generation skills. The Centre has also
                    the goal of creation of sustainable and self-reliant rural
                    communities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-5 mb-4 shadow ">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <img
                  src="images/slidernew1.jpg"
                  className="img-fluid rounded-start"
                  alt="..."
                />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <p className="card-text">
                    In 2020, as the nation reeled under the weight of the
                    Covid-19 pandemic, which threatened the lives of millions
                    and brought the entire nation to a standstill, the
                    Pallottine Welfare Centre proved itself as an outstanding
                    source of service and support to migrant workers,
                    construction labourers, rickshaw pullers, daily beggars,
                    etc. It served cooked food for 8260 migrant workers in
                    Bilaspur and provided substantial amount of dry ration to
                    650 families in the same city and its neighbourhood. The
                    present office bearers of the Pallottine Welfare Centre are
                    very Rev. Fr. Bipin Kishore Minj, the President and Rev. Fr.
                    Rashmikant Nayak, the Director and Secretary, and Rev. Fr.
                    Albinos Tirkey, the Treasurer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
