import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  let location = useLocation();
  const url = location.pathname;
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const menuItems = [
    { name: "Home", link: "/", subItems: [] },
    {
      name: "About Us",
      link: "aboutus",
      subItems: [
        { name: "Our Vision", link: "/our-vision" },
        { name: "Our Mission", link: "/mission" },
        { name: "Our Objectives", link: "/objectives" },
        { name: "History", link: "/history" },
      ],
    },
    {
      name: "On Going Projects",
      link: "on-going-projects",
      subItems: [{ name: "Projects Completed", link: "/projects-completed" }],
    },
    {
      name: "Our Team",
      link: "our-team",
      subItems: [
        { name: "Social Work Commission", link: "/social-work-commission" },
        { name: "Executive Committee", link: "/executive-committee" },
      ],
    },
    {
      name: "Gallery",
      link: "gallery",
      subItems: [{ name: "YouTube Video Gallery", link: "/video-gallery" }],
    },
    { name: "Contact Us", link: "/contact-us", subItems: [] },
  ];

  return (
    <>
      <header id="header" className="header-section">
        <div className="top-header">
          <div className="container">
            <div className="top-content-wrap row">
              <div className="col-sm-8">
                <ul className="left-info">
                  <li>
                    <i className="fa fa-home">
                      <span style={{ fontSize: "14px" }}>
                        &nbsp;&nbsp;Shanti Niwas, Bilaspur, Awaspara, Chhatauna,
                        Behind New High Court , P. O. Hirri Mines, Dist.
                        Bilaspur ( C.G.) - 495222
                      </span>
                    </i>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4 d-none d-md-block">
                <ul className="right-info">
                  <li>
                    <i className="fa fa-envelope" />
                  </li>
                  <li>
                    <a href="/">pwcbilaspur@rediffmail.com</a>
                  </li>
                  <li>
                    <i className="fa fa-phone" />
                  </li>
                  <li>
                    <a href="/">7587254532</a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-header">
          <div className="container">
            <div className="bottom-content-wrap row">
              <div className="col-sm-3">
                <div className="site-branding">
                  <Link to={"/"}>
                    <img src="images/45.png" alt="Brand" />
                  </Link>
                </div>
              </div>
              <div className="col-sm-9 text-right">
                <ul id="mainmenu" className="nav navbar-nav nav-menu">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={item.subItems.length ? "dropdown" : ""}>
                      <NavLink
                        to={item.link}
                        className={`${
                          item.link === url ||
                          item.subItems.some((subItem) => subItem.link === url)
                            ? "active"
                            : ""
                        }`}>
                        {item.name}
                      </NavLink>

                      {item.subItems.length > 0 && (
                        <ul>
                          {item.subItems.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <NavLink
                                to={subItem.link}
                                className={
                                  subItem.link === url ? "active" : ""
                                }>
                                {subItem.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>

                <a href="#abouthome" className="default-btn">
                  Donate Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Hamburger Icon for Menu Toggle */}
      <div className="navbar-toggler d-md-none" onClick={toggleMenu}>
        <div className="menu-icon"></div>
        <div className="menu-icon"></div>
        <div className="menu-icon"></div>
      </div>
      {/* Offcanvas menu */}
      <div
        className={`offcanvas offcanvas-start d-md-none ${
          showMenu ? "show" : ""
        }`}
        tabIndex="-1"
        id="offcanvas">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Menu</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={toggleMenu}></button>
        </div>
        <div className="offcanvas-body">
          <ul>
            <li className="dropdown">
              <Link to={"/"}>
                <span>Home</span>
              </Link>
            </li>
            <li className="dropdown">
              <span
                onClick={(e) => e.preventDefault()}
                data-bs-toggle="dropdown">
                About Us
              </span>{" "}
              <i className="fa fa-caret-down" style={{ color: "blue" }} />
              <ul className="dropdown-menu">
                <li>
                  <a href="our-vision">Our Vision</a>
                </li>
                <li>
                  <a href="mission">Our Mission</a>
                </li>
                <li>
                  <a href="objectives">Our Objectives</a>
                </li>
                <li>
                  <a href="history">History</a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <span
                onClick={(e) => e.preventDefault()}
                data-bs-toggle="dropdown">
                On Going Projects
              </span>{" "}
              <i className="fa fa-caret-down" style={{ color: "blue" }} />
              <ul className="dropdown-menu">
                <li>
                  <a href="projects-completed">Projects Completed</a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <span
                onClick={(e) => e.preventDefault()}
                data-bs-toggle="dropdown">
                Our Team
              </span>{" "}
              <i className="fa fa-caret-down" style={{ color: "blue" }} />
              <ul className="dropdown-menu">
                <li>
                  <a href="social-work-commission">Social Work Commission</a>
                </li>
                <li>
                  <a href="executive-committee">Executive Committee</a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <span
                onClick={(e) => e.preventDefault()}
                data-bs-toggle="dropdown">
                Gallery
              </span>{" "}
              <i className="fa fa-caret-down" style={{ color: "blue" }} />
              <ul className="dropdown-menu">
                <li>
                  <a href="youtube-video-gallery">YouTube Video Gallery</a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="contact-us">Contact Us</a>
            </li>
            <li className="dropdown">
              <button
                data-toggle="modal"
                data-target="#exampleModalCenter"
                className="btn btn-primary getstarted">
                Donate Now
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
