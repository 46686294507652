import React from "react";

const ProjectComplete = () => {
  return (
    <>
      <section className="about-section bd-bottom shape circle padding">
        <div className="container">
          <h2 className="text-center">Dry Ration Distribution</h2>
          <br />
          <div className="row">
            <div className="gallery">
              <div className="gallery-item">
                <img src="images/35.jpg" alt="Social-Economic" />
              </div>
              <div className="gallery-item">
                <img src="images/20.jpg" alt="Pallottine" />
              </div>
              <div className="gallery-item">
                <img src="images/34.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/21.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/23.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/24.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/25.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/35.jpg" alt="IMG_20200524_114858" />
              </div>
              <div className="gallery-item">
                <img src="images/38.jpg" alt="IMG_20200524_114858" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectComplete;
