import React from "react";

const Footer = () => {
  return (
    <>
      <>
        <section className="widget-section padding">
          <div className="container">
            <div className="widget-wrap row">
              <div className="col-md-4 xs-padding">
                <div className="widget-content">
                  <h3>Donate Now</h3>

                  <ul className="widget-link">
                    <li>
                      <p>
                        <b>A/c Name:</b> Pallottine Welfare Centre
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>A/c No:</b> 34160100008521
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>IFSC: </b>BARB0RAHNGI(Fifth character is zero)
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>MICR Code:</b>495012004
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Bank: </b>Bank of Baroda (BOB)
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 xs-padding">
                <div className="widget-content">
                  <h3>Recent Campaigns</h3>

                  <ul className="social-icon">
                    <li>
                      <a
                        href="https://www.facebook.com/pallottinewelfarecentre.pwc"
                        target="_blank"
                        rel="noreferrer">
                        <img src="images/fb.png" alt="facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/webinane"
                        target="_blank"
                        rel="noreferrer">
                        <img src="images/twitter-icon.jpg" alt="twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCstkLGOKUY3Kv8Ij89SVNSg"
                        target="_blank"
                        rel="noreferrer">
                        <img src="images/youtube.jpg" alt="twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://themes.webinane.com/wp/lifeline/"
                        target="_blank"
                        rel="noreferrer">
                        <img src="images/insta.jpg" alt="twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 xs-padding">
                <div className="widget-content">
                  <h3>Charitify Location</h3>
                  <iframe
                    loading="lazy"
                    style={{ border: "0", height: "180px" }}
                    tabIndex="0"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14788.852765715563!2d82.13216042146318!3d22.07967375113602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a280b117b9ab6a7%3A0xc6f3f89d9eac7caf!2sBilaspur%2C%20Chhattisgarh%20495001!5e0!3m2!1sen!2sin!4v1594373464297!5m2!1sen!2sin"
                    frameBorder="0"
                    title="ourLocation"
                    allowFullScreen=""
                    aria-hidden="false"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer-section">
          <div className="container">
            <div className="row">
              <div className="col-md-8 sm-padding">
                <div className="copyright">
                  Copyright © {new Date().getFullYear()} - All Rights Reserved
                  By -{" "}
                  <span style={{ color: "#ac7865" }}>
                    Pallottine Welfare Centre - Bilaspur
                  </span>
                </div>
              </div>
              <div className="col-md-4 sm-padding">
                <div className="copyright">
                  <span>Powered by</span>
                  <a
                    href="https://www.boscosofttech.com/"
                    rel="noreferrer"
                    className="ml-2"
                    style={{ color: "#ac7865" }}
                    target="_blank">
                    Bosco Soft Technologies Pvt. Ltd.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <a
          href="#header"
          id="scroll-to-top"
          onClick={() => window.scrollTo(0, 0)}>
          <i className="arrow_up" />
        </a>
      </>
    </>
  );
};

export default Footer;
