import React from "react";
import { Link } from "react-router-dom";

const Ourteam = () => {
  return (
    <>
      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="single-team">
              <div className="img-area">
                <img src="images/28.png" className="img-responsive" alt="" />
                <div className="social">
                  <Link
                    to="https://www.facebook.com/people/@/webinane"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fa fa-facebook teamimg text-danger"></i>
                  </Link>
                  <Link
                    to="https://plus.google.com/+Webinane"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fa fa-google-plus teamimg text-danger ml-2"></i>
                  </Link>
                </div>
              </div>
              <div className="img-text">
                <h4>Rev. Bipin Kishor Minj</h4>
                <h5>President</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="single-team">
              <div className="img-area">
                <img src="images/29.jpg" className="img-responsive" alt="" />
                <div className="social">
                  <Link
                    to="https://www.facebook.com/people/@/webinane"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fa fa-facebook teamimg text-danger"></i>
                  </Link>
                  <Link
                    to="https://plus.google.com/+Webinane"
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="fa fa-google-plus teamimg text-danger ml-2"></i>
                  </Link>
                </div>
              </div>
              <div className="img-text">
                <h4>Rev. Rashmikant Nayak</h4>
                <h5>Secretary</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ourteam;
