import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import History from "./SubPages/AboutUs/History";
import OurMission from "./SubPages/AboutUs/OurMission";
import OurObjectives from "./SubPages/AboutUs/OurObjectives";
import OurVission from "./SubPages/AboutUs/OurVission";
import Home from "./home";
import MainLayout from "./layout";
import About from "./Components/About";
import Ongoing from "./SubPages/OnGoingProjects/Ongoing";
import ProjectComplete from "./SubPages/OnGoingProjects/ProjectComplete";
import RecentNews from "./SubPages/Recent News/RecentNews";
import RecentCause from "./SubPages/Recent News/RecentCause";
import Ourteam from "./SubPages/OurTeam/Ourteam";
import SocialWorkCommision from "./SubPages/OurTeam/SocialWorkCommision";
import ExecutiveCommitte from "./SubPages/OurTeam/ExecutiveCommitte";
import Contact from "./SubPages/ContactUs/Contact";
import Detailedongoing from "./SubPages/OnGoingProjects/detailedongoing";
import Gallery from "./SubPages/Gallery/gallery";
import VideoGallery from "./SubPages/Gallery/VideoGallery";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<MainLayout />}>
            <Route path="aboutus" element={<About />} />
            {/* About Us Pages Routing */}
            <Route path="history" element={<History />} />
            <Route path="mission" element={<OurMission />} />
            <Route path="objectives" element={<OurObjectives />} />
            <Route path="our-vision" element={<OurVission />} />
            <Route path="on-going-projects" element={<Ongoing />} />
            <Route path="projects-completed" element={<ProjectComplete />} />
            <Route path="recentnews" element={<RecentNews />} />
            <Route path="ourcause" element={<RecentCause />} />
            <Route path="our-on-going-project" element={<Detailedongoing />} />
            <Route path="our-team" element={<Ourteam />} />
            <Route
              path="social-work-commission"
              element={<SocialWorkCommision />}
            />
            <Route path="executive-committee" element={<ExecutiveCommitte />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="gallery" element={<Gallery />} />
            {/* About Us Pages Routing */}
            <Route path="video-gallery" element={<VideoGallery />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
