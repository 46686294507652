import React from "react";
import { Link } from "react-router-dom";

const AboutUsHome = () => {
  return (
    <>
      <div id="abouthome"></div>
      <div className="team-section bg-grey bd-bottom circle shape padding">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-9 col-md-12 col-sm-12 wow fadeInLeft delay-04s align-self-center"
              style={{ visibility: "visible", animationName: "fadeInLeft" }}>
              <div className="text">
                <h1>Welcome to Pallotine Welfare Centre</h1>
                <p className="mb-4" id="aboutdesc">
                  Pallottine welfare centre began as non-profitable charitable
                  organization to help out poorest of the poor with right motive
                  of helping the needy. The social actions being carried in
                  different forms in different places. Khrist Jyoti Provuice
                  Raipur, has extended its work by way of taking care of Lepers,
                  Db Tech training centre for youth, Shelter home for
                  unprivileged children.
                </p>
                <p className="mb-30" id="aboutdesc">
                  Covid-19 response to livelihood, helping and educating the
                  bright students from remote areas. Such noble initiatives are
                  taken as priority of the Welfare Centre. New upcoming projects
                  are being planned and will be implemented in the present
                  financial year.
                </p>
                <Link to="/aboutus" className="default-btn">
                  Read More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-12 col-sm-12 wow fadeInRight delay-04s"
              style={{ visibility: "visible", animationName: "fadeInRight" }}>
              <div className="hotel-banner">
                <div className="pull-right">
                  <div className="donate-us-box">
                    <h5>GIVE YOUR DONATIONS</h5>
                    <span>Donation needed</span>{" "}
                    <span className="amount-figures">
                      <strong>₹</strong>
                      25,000!
                    </span>{" "}
                    <span>Collection Donation</span>
                    <span className="amount-figures coloured" id="amountnews">
                      <strong>₹</strong>
                      3,025!
                    </span>{" "}
                    <span className="cell">
                      <i className="fa fa-phone" />
                      7587254532
                    </span>
                    <center>
                      <button
                        data-toggle="modal"
                        data-target="#myModal"
                        data-url="https://pwckjp.org/"
                        data-type="general"
                        className=" default-btn"
                        data-security="16e439bce5">
                        Donate Now
                      </button>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsHome;
