import React from 'react'
import { Link } from 'react-router-dom';

const UpcomeEvents = () => {
  const recentnews = [
    {
      id: '1',
      image: 'images/basic.jpg',
      date: 'September 21 - 2017',
      title: 'Basic Coaching Class For Poor Children Class up to 5th',
      content: 'Khrist Jyoti Province,Raipur Basic Coaching Class for poor Children class up to 5th By Pallottine Welfare Centre (PWC) Shanti Niwas,Bilaspur.'
    },
    {
      id: '2',
      image: 'images/Education.jpg',
      date: 'September 20 - 2017',
      title: 'Education Kit for Children of Poor & Migrant Families',
      content: 'Education Kit for Children of Poor & Migrant Families Arranged by Pallotine Welfare Centre Shanti Niwas, Bilaspur Fr. Rashmikant Nayak.'
    },
    {
      id: '3',
      image: 'images/migrant.jpg',
      date: 'September 19 - 2017',
      title: 'Migrant Workers',
      content: ' PWC is a non-governmental charitable organization that helps restore the Socio- economic abilities of children, youth, and women.It promotes social justice to build a society.'
    }
  ]
  return (
    <>
      <section className="team-section bg-grey bd-bottom circle shape padding">
        <div className="container">
          <div className="section-heading text-center mb-40">
            <div className="sec-heading">
              <h2><strong style={{ color: "#16846e" }}>Our Recent</strong> <strong style={{ color: "#f5ab21" }}>News</strong></h2>
              <span className="heading-border" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 xs-padding">
              <div className="blog-items grid-list row">
                {recentnews?.map((recent, index) => (
                  <div key={index} className="col-md-4 padding-15">
                    <div className="blog-post">
                      <img src={recent?.image} alt="blog post" />
                      <div className="blog-content">
                        <span className="date">
                          <i className="fa fa-clock-o" /> {recent?.date}
                        </span>
                        <h3>
                          <Link to={`recentnews?newsid=${recent?.id}`}>{recent.title}</Link>
                        </h3>
                        <p>
                          {recent?.content}
                        </p>
                        <Link to={`recentnews?newsid=${recent?.id}`} className="post-meta">Read More</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default UpcomeEvents
