import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VideoGallery = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const redirectToYouTube = () => {
        window.open("https://www.youtube.com/@pallottinewelfarecentrepwc3086", "_blank", "noreferrer noopener");
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://youtube.googleapis.com/youtube/v3/search',
                    {
                        params: {
                            part: 'snippet',
                            channelId: 'UCstkLGOKUY3Kv8Ij89SVNSg',
                            maxResults: 6,
                            order: 'date',
                            key: 'AIzaSyBXrHCFqaskrzAG1hkwOI5DWe6Yk6GcDTc'
                        }
                    }
                );
                setVideos(response?.data?.items);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Error fetching data. Please try again later.');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="container">
            {loading ? (
                <div className="text-center mt-4">
                    <p><b>Loading...</b></p>
                </div>
            ) : error ? (
                <div className="text-center mt-4">
                    <p>{error}</p>
                </div>
            ) : (
                <>
                    <div className="row row-cols-1 row-cols-md-3 g-4 mt-4">
                        {videos?.map(video => (
                            <div className="col" key={video.id.videoId}>
                                <div className="card h-100 border-0 shadow">
                                    <div className="ratio ratio-16x9">
                                        <iframe
                                            src={`https://www.youtube.com/embed/${video.id.videoId}`}
                                            title={video.snippet.title}
                                            frameBorder="0"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title fs-5">{video.snippet.title}</h5>
                                        <p className="card-text text-muted">{video.snippet.channelTitle}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="text-center mt-4">
                        <button onClick={redirectToYouTube} className="btn btn-success getstarted">View More</button>
                    </div>
                </>
            )}
        </div>
    );
};

export default VideoGallery;
