import React, { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
const SinglePageAccordion = ({ data }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="accordion" id="accordionExample">
      {data.map((item, index) => (
        <div key={index} className="card shadow-sm mb-3">
          <div className="card-header" id={`heading${index}`}>
            <h2 className="mb-0">
              {openIndex === index && (
                <FaCheckCircle style={{ color: "green" }} />
              )}
              <button
                className="btn btn-link btn-block text-left d-flex justify-content-between"
                type="button"
                onClick={() => toggleAccordion(index)}>
                {item.title}
                {openIndex === index ? <BsChevronUp /> : <BsChevronDown />}
              </button>
            </h2>
          </div>
          <div
            id={`collapse${index}`}
            className={`collapse ${openIndex === index ? "show" : ""}`}
            aria-labelledby={`heading${index}`}
            data-parent="#accordionExample">
            <div className="card-body">
              <p>{item.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const OurObjectives = () => {
  const accordionData = [
    {
      title: "To minimize Poverty among the rural and backward people",
      content: "",
    },
    {
      title:
        "To Establish basic training center for destitute, orphans, dropouts, physically challenged people.",
      content: "",
    },
    {
      title:
        "To Create all round development vulnerable children in the society.",
      content: "",
    },
    {
      title:
        "To grant Scholarships, stipend , free studentship as financial assistance",
      content: "",
    },
    {
      title:
        "To encourage the provision of sanitary facilities and promotion of physical health.",
      content: "",
    },
    {
      title: "To strive for educational advancement for youth SC and ST.",
      content: "",
    },
    {
      title:
        "To ensure welfare and protection of vulnerable group of the society",
      content: "",
    },
    {
      title:
        "To provide education, medicines, food, clothing for the poor and needy.",
      content: "",
    },
    {
      title: " To empower the women for self sufficiency.",
      content: "",
    },
    {
      title: "To motivate the wavering youth to shine out in the society",
      content: "",
    },
  ];

  return (
    <div className="container">
      {/* <h2 className="mt-5 mb-3 text-center">Our Objectives</h2> */}
      <br />
      <SinglePageAccordion data={accordionData} />
    </div>
  );
};

export default OurObjectives;
