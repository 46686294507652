import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useLocation } from "react-router-dom";
const MainLayout = () => {
  let location = useLocation();
  const url = location.pathname;
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    const path = {
      "/aboutus": "About US",
      "/our-vision": "Our Vision",
      "/mission": "Our Missions",
      "/objectives": "Our Objectives",
      "/history": "History",
      "/on-going-projects": "On Going Projects",
      "/projects-completed": "Projects Completed",
      "/our-team": "Our Team",
      "/social-work-commission": "Social Work Commission",
      "/executive-committee": "Executive Commitiee",
      "/contact-us": "Contact Us",
      "/our-on-going-project": "On Going Projects",
      "/gallery": "Gallery",
      "/video-gallery": "Video Gallery",
      "/ourcause": "Our Cause",
      "/recentnews": "Recent News",
    };
    setPageTitle(path[url] ? path[url] : url);
  }, [url]);

  return (
    <>
      <Header />
      <div style={{ minHeight: "70vh" }}>
        <div className="top-image">
          <div className="layer">
            <h1 className="headingdata">{pageTitle}</h1>
          </div>
          <img src="images/47.jpg" alt="" />
        </div>

        <Outlet />
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default MainLayout;
