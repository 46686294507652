import React from "react";

const OurMission = () => {
  return (
    <>
      <div className="container">
        <div className="card mt-5 mb-4 shadow ">
          <div className="card-body">
            <h3 className="text-left">
              <span style={{ color: "red" }}>Mission</span>
            </h3>
            <p>
              Uplift the marginalized and downtrodden by availing basic
              necessary skills and technique to improve their fundamental
              lifestyle , and poor themselves Human with equality and dignity
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurMission;
