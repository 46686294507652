import React from "react";
import Slider from "./Components/Slider";
import Header from "./Components/Header";
import OurCasues from "./Components/OurCasues";
import Testimonials from "./Components/Testimonials";
import UpcomeEvents from "./Components/UpcomeEvents";
import Footer from "./Components/Footer";
import AboutUsHome from "./Components/AboutUsHome";
import OurProjects from "./Components/OurProjects";

const Home = () => {
  return (
    <>
      <Header />
      <Slider />
      <AboutUsHome />
      <OurCasues />
      <Testimonials />
      <UpcomeEvents />
      <OurProjects />
      <Footer />
    </>
  );
};

export default Home;
