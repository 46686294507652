import React from 'react';
import './Project.css'

const OurProjects = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading text-center mb-40 mt-3">
                        <div className="sec-heading">
                            <h2><strong style={{ color: "#16846e" }}>Our Welfare</strong> <strong style={{ color: "#f5ab21" }}>Projects</strong></h2>
                            <span className="heading-border" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-md-4 mb-4">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="icon-box">
                                <i style={{ color: "#A5A4A4", fontSize: "48px" }} className="fa fa-envelope" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="project-detail">
                                    <h4>Employment Generating Projects</h4>
                                <p>
                                    PWC has taken steps to empower the village business attitudes of
                                    the people by creating Cooperative Shops of income. By which
                                    they will be economically small...
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-4">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="icon-box">
                                <i style={{ color: "#A5A4A4", fontSize: "48px" }} className="fa fa-users" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="project-detail">
                                    <h4>Families / Migrant Workers</h4>
                                <p>
                                    Covid-19 has brought massive trauma to the people in and around. Migrant workers and poor people are the most victims. PWC has planned to executive.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-4">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="icon-box">
                                <i style={{ color: "#A5A4A4", fontSize: "48px" }} className="fa fa-search" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="project-detail">
                                    <h4>Livelihood & Sustainable Life</h4>
                                <p>
                                    The project aims to contribute PWC efforts to eliminate poverty through various livelihood projects such as Community Kitchen, Kitchen garden, piggery
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-4">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="icon-box">
                                <i style={{ color: "#A5A4A4", fontSize: "48px" }} className="fa fa-music" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="project-detail">
                                    <h4>Poor/ Destitutes/Beggars</h4>
                                <p>
                                    PWC has initiated an action more basic to the vulnerable group of people at Bilaspur. The centre by the help of Khrist Jyoti Province and St. Vincent
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-4"> 
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="icon-box">
                                <i style={{ color: "#A5A4A4", fontSize: "48px" }} className="fa fa-user" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="project-detail">
                                    <h4>Students</h4>
                                <p>
                                    PWC intends to open few basic Coaching Centres for poor children to aquire primary education. The direct beneficiaries are from poor and migrant famil
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurProjects;
