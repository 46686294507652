import React from 'react';
import { Carousel } from 'react-bootstrap';

const Slider = () => {
    const slidernew = [
        {
            image: 'images/slidernew1.jpg'
        },
        {
            image: 'images/slidernew2.jpg'
        },
        {
            image: 'images/slidernew3.jpg'
        }
    ]
    return (
        <Carousel
            interval={5000}
            prevLabel=''
            nextLabel=''
            ride="carousel"
            wrap={true}
            indicators={true}
            pause={true}
            fade={true}
            controls={true}
            touch={true}
            keyboard={true}
            className="slider-wrapper"
        >
            {slidernew.map((sliderdata, index) => (
                <Carousel.Item key={index}>
                    <img
                        className="d-block w-100"
                        src={sliderdata.image}
                        alt="Slider"
                        id='myslider'
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default Slider;
